import React, {useEffect, useRef, useState} from 'react';
import './fAQAccordion.css';

const FAQAccordion = ({ icon, question, answer, isOpen, onClick }) => {

    const answerRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        // Update height whenever the isOpen state changes
        if (isOpen) {
            setHeight(answerRef.current.scrollHeight);
        } else {
            setHeight(0);
        }
    }, [isOpen]);


    return (
        <div className={`faq-item ${isOpen ? 'open' : ''}`}>
            <div className={`faq-question ${isOpen ? 'open' : ''}`} onClick={onClick}>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <div>
                    {/*<div style={{*/}
                    {/*    display: 'flex', justifyContent: 'center', alignItems: 'center',*/}
                    {/*    backgroundColor: "rgb(249, 75, 37,0.9)",*/}
                    {/*    borderRadius: "10px",*/}
                    {/*    padding: "5px 10px"*/}
                    {/*}}>*/}
                    {/*    */}


                        <img decoding="async" sizes="28px"
                             src={icon} style={{width: "64px", height: "64px"}}
                             alt=""
                        />
                    </div>
                        <p style={{fontSize: "16px", marginLeft: "10px", fontWeight: "500"}}>{question}</p>
                    </div>
                    <div className="faq-toggle-icon">{isOpen ? '▲' : '▼'}</div>
                </div>
                <div
                    className="faq-answer"
                    ref={answerRef}
                    style={{
                        height: `${height}px`,
                        overflow: 'hidden',
                    transition: 'height 0.5s ease-in-out'
                }}
            >
                <div style={{padding:"20px"}}>
                    <p style={{textAlign: "left", fontSize: "16px"}}>{answer}</p>

                </div>
            </div>
        </div>
    );
};

export default FAQAccordion;
