import React, {useState} from 'react';

import './home2Des.css'

const Home2Des = ({itemList}) => {

    const [selectedItem, setSelectedItem] = useState(1);

    const handleItemClick = (index) => {
        setSelectedItem(index);
    };
    return (
        <div className="home2-section">
            <div className="home2-container">
                <div className="home2-content">
                    <div className="home2-content-left">
                        {itemList.items.map((item, index) => (
                            <div key={index} className="home2-content-left-item">
                                {/*<div className={`home2-content-left-item-content ${selectedItem === index ? 'selected' : ''}`}*/}
                                {/*     onClick={() => handleItemClick(index)}>*/}
                                <div className={`home2-content-left-item-content selected`}>
                                    <div className="icon-title">
                                        {/*<div className="icon-box">*/}
                                        {/*    <div className="icon-svg">*/}
                                        {/*        <div className="content-icon">*/}
                                                    <div>
                                                        {/*<div style={{*/}
                                                        {/*display: 'flex', justifyContent: 'center', alignItems: 'center',*/}
                                                        {/*backgroundColor: "rgb(249, 75, 37,0.9)",*/}
                                                        {/*borderRadius: "10px",*/}
                                                        {/*padding: "5px 10px"}}>*/}

                                                        <img decoding="async" sizes="28px"
                                                             src={item.icon} style={{width: "64px", height: "64px"}}
                                                             alt=""
                                                        />
                                                    </div>
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        <div className="icon-text">
                                            <p style={{textAlign: "left"}}>{item.iconText}</p></div>

                                    </div>
                                    <div className="icon-content">
                                        <p style={{textAlign: "left"}}>{item.description}</p>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>


                    {/*                    <div className="home2-content-right">*/}
                    {/*                        <div className='image'>*/}
                    {/*                            {itemList.items[selectedItem].imageList.map((item, index) => {*/}
                    {/*                                return (<img className="home2-image" src={item} alt={index}/>)*/}
                    {/*                            })}*/}
                    {/*                        </div>*/}
                    {/*                        <div className='image-bg'>*/}
                    {/*                            <div className='image'>*/}
                    {/*                                <img decoding="async" sizes="607px"*/}
                    {/*                                     srcSet="https://framerusercontent.com/images/rP9XZb2mFezYG79Iqw8e5me4sSs.svg?scale-down-to=512 512w,https://framerusercontent.com/images/rP9XZb2mFezYG79Iqw8e5me4sSs.svg 607w"*/}
                    {/*                                     src="https://framerusercontent.com/images/rP9XZb2mFezYG79Iqw8e5me4sSs.svg"*/}
                    {/*                                     alt="Feature Image"*/}
                    {/*/>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}

                </div>
            </div>
        </div>
    )
}
export default Home2Des;